
$sharedTheme:(
    black_color: #000000,
        white_color: #ffffff,
        primary_color: #014694,
        primary_topnav_color: #012e61,
        primary_light_color: #f0f7ff,
        background_color: #013775,
        volcanic_rock: #696969,
        light_slate_gray: #7d899840,
        shaded_white: #f8f9fa,
        winter_scene: #bcc9d9,
        is_valid_background: #f0f7ff,
        is_valid_border: #014694,
        secondary_color: #e4001b,
        menu_active_background: #014694,
        menu_active_color: #ffffff,
        black_with_alpha_29: #00000029,
        info_aboutme: #ffdbdf,
        subinfo_aboutme: #8a0010,
        switch_background: #fffafa,
        sea_leon: #7d8998,
        sugar_milk: #fdf5f6,
        apricot_haze: #ffa8b3,
        table_header_with_alpha_26: rgba(93, 104, 119, 0.15),
        morning_mist: #e6ebf0,
        secondary_text: #757575,
        black_with_alpha_1a: #0000001a,
        placeholder_text: #727272,
        hyper_blue: #0054a4,
        primary_text: #212121,
        navbar_text_color: #445974,
        table_header: #5d6877,
        secondary_backgroung_color_19: #8b041419,
        chefs_hat: #f1f5f9,
        pacific_depths: #004694,
        paris_paving: #707070,
        hedge_green: #01a217,
        midnight_color: #003680,
        gray_color: #ccc,
        light_gray_color: #f0f0f0,
        green_color: #009417
);
$themes: (
    freedom: $sharedTheme,
    ultimate: $sharedTheme,
    secur:$sharedTheme,
    healthsun: (
        black_color: #000000,
        white_color: #ffffff,
        primary_color: #183d6a,
        primary_topnav_color: #002d52,
        primary_light_color: #fff3d7,
        background_color: #003b6a,
        volcanic_rock: #696969,
        light_slate_gray: #7d899840,
        shaded_white: #f8f9fa,
        winter_scene: #bcc9d9,
        is_valid_background: #fefbf6,
        is_valid_border: #fdb718,
        secondary_color: #e4001b,
        menu_active_background: #fdb718,
        menu_active_color: #003b6a,
        black_with_alpha_29: #00000029,
        info_aboutme: #fee5a9,
        subinfo_aboutme: #003b6a,
        switch_background: #fff3d7,
        sea_leon: #7d8998,
        sugar_milk: #fdf5f6,
        apricot_haze: #ffa8b3,
        table_header_with_alpha_26: #5d687726,
        morning_mist: #e6ebf0,
        secondary_text: #757575,
        black_with_alpha_1a: #0000001a,
        placeholder_text: #727272,
        hyper_blue: #0054a4,
        hazelwood_color: #fff3d7,
        lost_in_space: #003b6a,
        atlantic_navi: #183d6a,
        master_nacho: #fdb718,
        eye_ball: #fefbf6,
        wold_world: #8fa9c7,
        cheffs_hat: #f1f5f8,
        bleached_sunflower: #fee5a9,
        master_nacho_varient: #fdb717,
        fat_back: #fffaf0,
        gray_color: #ccc,
        light_gray_color: #f0f0f0,
        green_color: #009417
    ),
    optimum: (
        black_color: #000000,
        white_color: #ffffff,
        primary_color: #005f68,
        primary_topnav_color: #01464d,
        primary_light_color: #ebf4f2,
        background_color: #01393e,
        volcanic_rock: #696969,
        light_slate_gray: #7d899840,
        shaded_white: #f8f9fa,
        winter_scene: #bcc9d9,
        is_valid_background: #f5faf9,
        is_valid_border: #005f68,
        secondary_color: #e4001b,
        menu_active_background: #005f68,
        menu_active_color: #ffffff,
        primary_text: #212121,
        black_with_alpha_29: #00000029,
        info_aboutme: #d7e9e6,
        subinfo_aboutme: #005f68,
        switch_background: #ebf4f2,
        sea_leon: #7d8998,
        sugar_milk: #fdf5f6,
        apricot_haze: #ffa8b3,
        table_header_with_alpha_26: #5d687726,
        morning_mist: #e6ebf0,
        secondary_text: #757575,
        black_with_alpha_1a: #0000001a,
        placeholder_text: #727272,
        hyper_blue: #0054a4,
        hazelwood_color: #fff3d7,
        cheffs_hat: #f1f5f8,
        wakeme_green: #005f68,
        mint_twist: #99c8bd,
        hyper_blue_9f: #00539f,
        mont_blank_with_c9: #8698c9,
        mont_blank_with_cc: #8d9ecc,
        mont_blank_with_d6: #a3b0d6,
        misty_mountains: #c7cee6,
        ice_desert: #d1d7eb,
        misty_mountains_2: #bfc8e3,
        over_the_moon: #a6b3d7,
        old_world_2: #8fa9c7,
        peacock_pride: #015f67,
        nordic_noir: #01393e,
        everlasting_ice: #f5faf9,
        whispery_breeze: #d7e9e6,
        enchanging_ivy: #325d53,
        warp_drive: #ebf4f2,
        gray_color: #ccc,
        light_gray_color: #f0f0f0,
        green_color: #009417
    )
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

@include themify($themes) {
    .red {
        color: themed("secondary_color");
    }

    .primary {
        color: themed("primary_color");
    }

    .btn {
        &.btn-primary {
            background: themed("primary_color");
            border: 1px solid themed("primary_color");
            color: themed("white_color");
        }

        &.btn-border-primary {
            background: transparent;
            border: 1px solid themed("primary_color");
            color: themed("primary_color");
        }
    }

    .form-label {
        color: themed("black_color");
        span {
            color: themed("secondary_color");
        }
    }
    .form-check-input:checked {
        background-color: themed("primary_color");
        border-color: themed("primary_color");
    }
    .custom-table-dropdown {
        &.form-select {
            border: 1px solid themed("primary_color");
            color: themed("primary_color");
        }
    }
    .custom-top-header-alert {
        background: themed("info_aboutme");
        color: themed("primary_text");
        border: themed("info_aboutme");
        .btn-primary {
            background-color: themed("subinfo_aboutme");
            border-color: themed("subinfo_aboutme");
        }
    }
    .admin-badge-top {
        background: #ffffff33;
        color: themed("white_color");
    }
    .form-content {
        .form-control {
            background: themed("shaded_white");
            border: 1px solid themed("winter_scene");

            &.is-valid,
            &:focus {
                border: 1px solid themed("is_valid_border");
                background: themed("is_valid_background");

                ~ .field-icon {
                    i {
                        color: themed("is_valid_border");
                    }
                    .svg-inline--fa {
                        path {
                            fill: themed("is_valid_border");
                        }
                    }
                }
            }

            &.is-invalid,
            &.is-invalid:focus {
                border: 1px solid themed("apricot_haze");
                background: themed("sugar_milk");

                ~ .field-icon {
                    i {
                        color: themed("secondary_color");
                    }
                    .svg-inline--fa {
                        path {
                            fill: themed("secondary_color");
                        }
                    }
                }
            }

            // &.is-valid {
            //     ~ .field-icon {
            //         i {
            //             color: themed("is_valid_border");
            //         }
            //         .svg-inline--fa {
            //             path {
            //                 fill: themed("is_valid_border");
            //             }
            //         }
            //     }
            // }
        }

        .field-icon {
            i {
                color: themed("sea_leon");
            }
            .svg-inline--fa {
                path {
                    fill: themed("sea_leon");
                }
            }
        }
    }

    .main-content {
        .card-form {
            border-bottom: 1px solid themed("morning_mist");
        }
    }

    .card-content {
        table {
            thead {
                background: themed("table_header_with_alpha_26");
            }
            tbody {
                tr:hover {
                    background-color: themed("is_valid_background");
                }
            }
            td {
                border-color: themed("morning_mist");
                a {
                    color: themed("primary_color");
                }
            }
        }
    }

    .page-item {
        button {
            color: themed("black_color");
        }
    }

    .authentication-main {
        background: themed("background_color");

        .authentication-left {
            background: #eee;
        }
        .authentication-right {
            background: themed("white_color");
        }

        .authentication-title {
            h1 {
                color: themed("primary_color");
            }
            span {
                color: themed("volcanic_rock");
            }
        }
    }

    .top-bar {
        &.sb-topnav {
            background-color: themed("primary_topnav_color");
        }
    }

    .sb-sidenav {
        button {
            color: themed("sea_leon");
            &:active {
                color: themed("sea_leon");
            }
        }
        .sb-sidenav-menu {
            .nav {
                .nav-link {
                    .sb-nav-link-icon,
                    span {
                        color: themed("primary_color");
                    }
                    &:hover {
                        background: themed("primary_light_color");
                        .sb-nav-link-icon,
                        span {
                            color: themed("primary_color");
                        }
                    }
                    &.active {
                        background: themed("menu_active_background");
                        .sb-nav-link-icon,
                        span {
                            color: themed("menu_active_color");
                        }
                    }
                }
            }
        }
    }

    .user-info-page {
        .card {
            i {
                color: themed("primary_color");
            }
            &.info-card {
                background: themed("primary_color");
            }
            &.cms-card {
                background: themed("info_aboutme");
                .mem-info {
                    .member-info {
                        color: themed("subinfo_aboutme");
                    }
                    .member-name {
                        color: themed("primary_text");
                        .mem-mini-info {
                            color: themed("subinfo_aboutme");
                        }
                    }
                }
            }
            .cms-switch {
                background: themed("switch_background");
                .sb-nav-link-icon {
                    color: themed("primary_color");
                }
                span {
                    color: themed("primary_text");
                }
                .form-switch {
                    .form-check-input {
                        //  background-color: themed("light_slate_gray");
                        &:checked {
                            background-color: themed("subinfo_aboutme");
                            border-color: themed("subinfo_aboutme");
                        }
                    }
                }
            }
        }
    }

    .header-dropdown {
        a {
            color: themed("primary_color");

            &:hover {
                background: themed("primary_light_color");
            }
        }
    }

    .label-info-main {
        i {
            color: themed("primary_color");
        }
    }

    .hours-block {
        > div {
            background: rgba(0, 84, 164, 0.05);
        }
    }

    .label-info-main {
        > div {
            span {
                a {
                    color: themed("primary_color");
                }
            }
        }
    }

    .information-icon {
        color: themed("primary_color");
    }

    .form-field {
        &.is-invalid {
            .react-datepicker__input-container::after {
                color: themed("secondary_color");
            }
        }
        &.is-valid {
            .react-datepicker__input-container::after {
                color: themed("is_valid_border");
            }
        }
        .react-datepicker__input-container::after {
            color: themed("sea_leon");
        }
    }

    .react-datepicker {
        border-color: themed("winter_scene");
    }

    .react-datepicker__header {
        background-color: themed("primary_color");
    }

    .react-datepicker__day-name,
    .react-datepicker__year-select,
    .react-datepicker__month-select {
        color: themed("white_color");
    }

    select.react-datepicker__month-select,
    select.react-datepicker__year-select {
        option {
            color: themed("black_color");
        }
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
        border-bottom-color: themed("shaded_white");
    }

    .react-datepicker__header,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: themed("winter_scene");
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: themed("primary_color");
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: themed("is_valid_background");
        color: themed("is_valid_border");
    }

    .react-datepicker__day--today {
        background-color: themed("light_gray_color");
        &.react-datepicker__day--selected {
            background-color: themed("primary_color");
        }
    }

    .menu-toggle-icon {
        color: themed("sea_leon");
    }

    .authentication-footer span,
    .total-record {
        color: themed("volcanic_rock");
    }

    .pagination {
        .page-link {
            border-color: themed("primary_color");
            i {
                color: themed("primary_color");
            }
            &:hover,
            &:focus {
                background: transparent;
            }
            &.current-page {
                cursor: default;
                border-color: themed("primary_color");
                background: themed("primary_color");
                color: themed("white_color");
            }
            &[disabled] {
                cursor: not-allowed;
                border-color: themed("gray_color");
                background: themed("white_color");
                i {
                    color: themed("gray_color");
                }
            }
        }
    }

    .stepper {
        .step {
            &:not(:last-child)::after {
                background: #8e8e8e;
            }
            span {
                background: themed("secondary_text");
                color: themed("white_color");
            }
            label {
                color: themed("secondary_text");
            }
            &.active {
                span {
                    background: themed("primary_color");
                    color: themed("white_color");
                }
                label {
                    color: themed("primary_color");
                }
            }
            &.disabled {
                span {
                    background: themed("secondary_text");
                    color: themed("white_color");
                }
                label {
                    color: themed("secondary_text");
                }
            }
            &.success {
                span {
                    background: themed("green_color");
                    color: themed("white_color");
                }
                label {
                    color: themed("green_color");
                }
            }
        }
    }
    .theme-authentication-screen {
        background-color: themed("primary_color");
        .theme-authentication-left {
            p {
                color: themed("white_color");
                &::after {
                    content: "";
                    background: themed("white_color");
                }
            }
        }
        .theme-logo {
            .theme-version {
                color: themed("secondary_text");
            }
        }
        .theme-title-main {
            .theme-title {
                color: themed("primary_text");
            }
        }
        .theme-welcome-text {
            .theme-title {
                color: themed("primary_text");
            }
            span {
                color: themed("secondary_text");
            }
        }
        .theme-information-message {
            background: themed("info_aboutme");
            color: themed("primary_text");
            a,
            b,
            p {
                color: themed("subinfo_aboutme");
            }
        }
        .theme-authentication-right {
            background: themed("white_color");
        }
        .theme-links {
            .theme-link-single {
                a {
                    color: themed("primary_color");
                }
                span {
                    color: themed("secondary_text");
                }
            }
        }
        .copyright-poweredby {
            .theme-copyright {
                color: themed("secondary_text");
            }
            .theme-powered-by {
                span {
                    color: themed("secondary_text");
                }
            }
        }
        .alpha-beta-text {
            background-color: themed("primary_topnav_color");
            color: themed("white_color");
        }
    }

    .form-switch {
        .form-check-input {
            border: 0;
            background-color: themed("sea_leon");
        }
    }
}

.theme-healthsun {
    .btn.btn-primary {
        background: #fdb718;
        border: 1px solid #fdb718;
        color: #183d6a;
    }
}
