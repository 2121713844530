@import "_breakpoints";

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin: 0;
  padding: 0;
}

.h-48 {
  height: 48px;
  @include miniheight {
    height: 40px;
  }
  @include xl {
    height: 40px;
  }
}

// Modal-popup back drop for entire app
.modal {
  backdrop-filter: brightness(0.5);
}

.element-hidden {
  display: none;
}

.element-visible {
  display: block;
}

.icon-ml {
  margin-left: 8px;
}

.icon-mr {
  margin-right: 8px;
}
.top-2 {
  top: 2px;
}
.alert {
  padding: 8px 16px;
  font-size: var(--bs-body-font-size);
}

.cursor-pointer {
  cursor: pointer;
}

.custom-table-dropdown {
  &.form-select {
    padding: 0.2rem 2rem 0.2rem 0.75rem;
  }
}

.authentication-header,
.stepper {
  margin-bottom: 24px;
  @include miniheight {
    margin-bottom: 20px;
  }
  @include lg {
    margin-bottom: 20px;
  }
}

.form-content {
  form {
    > .row {
      > .col-12:not(:last-child) {
        margin-bottom: 24px;
        @include miniheight {
          margin-bottom: 16px;
        }
      }

      > .col-12 {
        .col-sm-6.col-12:not(:last-child) {
          @include sm {
            margin-bottom: 24px;
          }
        }
      }
    }

    .form-action {
      margin-top: 8px;
    }
  }
  .form-label {
    font-weight: var(--bs-body-font-weight-medium);
    font-size: var(--bs-body-font-size);
    line-height: 20px;
    letter-spacing: 0.32px;
    span {
      margin-left: 2px;
    }
  }
  .form-control {
    padding: 12px 50px 12px 16px;
    border-radius: 8px;
    font-size: var(--bs-body-font-size);
    letter-spacing: 0.28px;
    @include miniheight {
      padding: 8px 50px 8px 16px;
    }
    @include xl {
      padding: 8px 50px 8px 16px;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .field-icon {
    right: 16px;
    opacity: 1;
    transition: ease-in-out 0.2s;

    .svg-inline--fa {
      height: 20px;
      vertical-align: middle;
    }

    i {
      font-size: calc(var(--bs-body-font-size) + 4px);
      position: relative;
      top: 2px;
    }
  }

  .information-icon {
    font-size: calc(var(--bs-body-font-size) - 4px);
    position: relative;
    top: -4px;
    cursor: pointer;
    font-weight: 600;
    i {
      font-size: var(--bs-body-font-size);
      margin-left: 2px;
      position: relative;
      top: 2px;
    }
  }
}

.btn {
  border-radius: 8px;
  font-size: var(--bs-body-font-size);
  line-height: 24px;
  font-weight: var(--bs-body-font-weight-medium);
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  letter-spacing: 0.16px;

  &:focus {
    box-shadow: none;
  }
}

a.btn {
  line-height: 36px;
}

.main-content {
  padding: 24px 24px;
  @media (min-width: 1200px) {
    padding: 24px 32px;
  }
  @media (min-width: 1537px) {
    padding: 32px 72px;
  }
  .card {
    border: 0;
    border-radius: 16px;
  }

  .card-body {
    padding: 24px;
    @media (min-width: 1537px) {
      padding: 32px;
    }
  }

  .card-form {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .content-subheader {
    margin-bottom: 22px;
  }
  .page-subheader {
    font-size: calc(var(--bs-body-font-size) + 6px);
    font-weight: 600;
    @include sm {
      font-size: calc(var(--bs-body-font-size) + 2px);
    }
  }
}

.content-header {
  margin-bottom: 16px;
  // @include sm {
  //     margin-bottom: 20px;
  // }

  .page-header {
    font-size: calc(var(--bs-body-font-size) + 12px);
    font-weight: 600;

    @include sm {
      font-size: calc(var(--bs-body-font-size) + 8px);
    }
  }
}

.card-content {
  table {
    thead {
      th {
        border: 0;
        vertical-align: middle;
        line-height: 1.3rem;
        padding: 10px 16px;
        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
      }
    }

    th,
    td {
      padding: 12px 16px;
      @media (min-width: 769px) {
        padding: 12px 24px;
      }
      font-size: var(--bs-body-font-size);

      @include sm {
        padding: 12px 16px;
      }
    }
  }
}

.page-item {
  button {
    font-size: var(--bs-body-font-size);
  }
}

.header-dropdown {
  border: 0;
  box-shadow: 0 8px 12px rgba(124, 142, 154, 0.2);
  border-radius: 4px;
  a {
    padding: 8px 16px;
    font-size: var(--bs-body-font-size);
    .svg-inline--fa {
      margin-right: 6px;
    }
  }
}

.label-info-main {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  i {
    position: absolute;
    font-size: calc(var(--bs-body-font-size) + 8px);
  }
  > div {
    flex-direction: column;
    display: flex;
    flex: 1;
    margin-left: 40px;
    label {
      font-size: var(--bs-body-font-size);
      font-weight: var(--bs-body-font-weight-medium);
      line-height: 20px;
      letter-spacing: 0.26px;
    }
    span {
      font-size: calc(var(--bs-body-font-size) + 2px);
      line-height: 26px;
      letter-spacing: 0.32px;
      a {
        text-decoration: underline !important;
      }
    }
  }
}

.hours-block {
  flex-wrap: wrap;
  > div {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    border-radius: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }

    @include xs {
      padding: 8px 12px;
    }
  }
  label {
    font-size: var(--bs-body-font-size) !important;
    font-weight: var(--bs-body-font-weight-medium);
    margin-bottom: 4px;
    @include xs {
      font-size: calc(var(--bs-body-font-size) - 3px) !important;
    }
  }
  span {
    margin: 0 !important;
    font-size: var(--bs-body-font-size) !important;
    line-height: 18px;
    @include xs {
      font-size: calc(var(--bs-body-font-size) - 3px) !important;
    }
  }
}

.map {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.provider-profile {
  display: flex;
  align-items: center;
  span {
    width: 72px;
    height: 72px;
    background: #eee;
    border-radius: 50%;
    overflow: hidden;

    @include xxl {
      width: 60px;
      height: 60px;
    }
    @include lg {
      width: 50px;
      height: 50px;
    }
    img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  > div {
    margin-left: 20px;
    @include lg {
      margin-left: 16px;
    }
  }
  h2 {
    font-size: calc(var(--bs-body-font-size) + 8px);
    font-weight: var(--bs-body-font-weight-medium);
    letter-spacing: 0.24px;

    @include xxl {
      font-size: calc(var(--bs-body-font-size) + 4px);
    }
    @include lg {
      font-size: calc(var(--bs-body-font-size) + 2px);
    }
  }
  small {
    font-size: var(--bs-body-font-size);
    margin-top: 4px;

    @include xxl {
      font-size: var(--bs-body-font-size);
      margin-top: 2px;
    }
    @include lg {
      font-size: calc(var(--bs-body-font-size) - 3px);
    }
  }
}

.provider-info {
  @include xs {
    flex-direction: column;
  }
  .label-info-main {
    width: 230px;
    @include md {
      width: 50%;
    }
    @include xs {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}

.react-datepicker {
  font-family: "Roboto", sans-serif;
  font-size: var(--bs-body-font-size);
  .react-datepicker__header {
    padding: 12px 0;
  }

  .react-datepicker__day-name {
    font-weight: var(--bs-body-font-weight-medium);
  }

  select {
    &.react-datepicker__month-select,
    &.react-datepicker__year-select {
      background: transparent url("./../assets/img/down-arrow.svg") right center no-repeat;
      appearance: none;
      border: 0;
      font-size: var(--bs-body-font-size);
      font-weight: var(--bs-body-font-weight-medium);
      position: relative;
      cursor: pointer;
      padding-right: 16px;
      background-size: 9px;
    }
    &:focus {
      outline: none;
      border: 0;
    }
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    top: 16px;
    width: 10px;
    height: 10px;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: #fff;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 12px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--outside-month {
  color: #ccc !important;
  pointer-events: none;
}

.react-datepicker__input-container {
  &::after {
    content: "\e9a0";
    font-family: "felthelt" !important;
    position: absolute;
    right: 16px;
    top: 50%;
    font-size: calc(var(--bs-body-font-size) + 4px);
    transform: translateY(-50%);
    // @include xl {
    //     top: 5px;
    // }
  }
  .form-control {
    letter-spacing: 1.4px;
  }
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #fff;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--today {
  border-radius: 8px;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  width: 2.3rem;
  padding: 4px;
}

.menu-toggle-icon {
  font-size: calc(var(--bs-body-font-size) + 4px);
  margin-left: 12px;

  .sb-sidenav-toggled & {
    margin-left: 0;
  }
}

.total-record {
  font-size: var(--bs-body-font-size);
  font-weight: 600;
}
.pagnination-info {
  font-weight: var(--bs-body-font-weight-medium);
  font-size: var(--bs-body-font-size);
  line-height: 20px;
  letter-spacing: 0.28px;
}
.pagination {
  .page-link {
    padding: 0 4px;
    border-radius: 4px !important;
    margin-right: 8px;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--bs-body-font-size) - 4px);

    i {
      font-size: calc(var(--bs-body-font-size) + 2px);
    }
  }
}

.custom-pop-up {
  .modal-content {
    border: 0;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0px 3px 6px #0000001a;
  }
  .modal-header {
    font-size: calc(var(--bs-body-font-size) + 4px);
    font-weight: 600;
    border: none;
    padding: 0px;
    margin-bottom: 16px;
  }
  .modal-body {
    padding: 0px;
    margin-bottom: 16px;
  }
  .modal-footer {
    padding: 0px;
    border: none;
  }
}
.modal-backdrop {
  width: 100%;
  height: 100%;
}
.modal-backdrop.show {
  opacity: 0.2;
}
.form-check-input {
  width: 1.5em;
  height: 1.5em;
}
